<template>
   <main>
      <!-- breadcrumb area start -->
      <section class="breadcrumb__area pt-30 grey-bg">
         <div class="container">
            <div class="breadcrumb__wrapper">
               <nav aria-label="breadcrumb">
                  <ol class="breadcrumb mb-0">
                     <li class="breadcrumb-item"><router-link to="/"><i class="far fa-home color-theme me-2"></i>Home</router-link></li>
                     <li class="breadcrumb-item active" aria-current="page">{{ $t('produtos.titulo') }}</li>
                  </ol>
               </nav>
            </div>
         </div>
      </section>

      <!-- produtos area start -->
      <section class="product__area pt-20 pb-40 grey-bg">
         <div class="container">
            <div class="cards cards-xs" v-if="pesquisa.resultado != null && pesquisa.resultado.length > 0">
               <produto v-for="(produto, index) in pesquisa.resultado" :key="index" :produto="produto" :index="index" :view="'Pesquisa'" />
            </div>
            <div v-if="pesquisa.resultado == null || pesquisa.resultado.length == 0" class="text-center mt-200 mb-200">{{ $t('produtos.semResultados') }}</div>
         </div>
      </section>
   </main>
</template>

<script>

import { mapState } from 'vuex'
import produto from '@/components/produtos/Produto.vue'

export default {
   name: 'Produtos',
   computed: {
		... mapState({
			pesquisa: state => state.pesquisa
		})
	},
   components: {
      produto
   },
   mounted() {
      if (this.pesquisa.historico == null) {
         this.$store.dispatch('pesquisar', {'tag': this.pesquisa.tag, 'filtro': this.pesquisa.filtro, 'valor': this.pesquisa.valor})
      } else {
         this.$store.dispatch('pesquisar', {'tag': this.pesquisa.historico.tag, 'filtro': this.pesquisa.historico.filtro, 'valor': this.pesquisa.historico.valor})
      }
   }
}

</script>