<template>
   <div class="product__item" :class="view == 'Carousel' ? 'carousel-250' : ''">
      <div class="product__thumb fix p-relative">
         <router-link :to="'/produto/'+ produto.tipoProduto +'/'+ produto.id" class="ratio ratio-3x4">
            <img :src="produto.nomeArquivo == null ? '' : produto.nomeArquivo" alt="produto" @error="imageError">
         </router-link>
         <div class="product__action p-absolute">
            <ul>
               <li v-if="produto.isExclusivo" :title="$t('produto.exclusivo')"><a href="javascript:;"><i class="fal fa-sparkles"></i></a></li>
               <li v-if="produto.isOtimizado" :title="$t('produto.otimizado')"><a href="javascript:;"><i class="fal fa-eraser"></i></a></li>
               <li v-if="produto.isTestado" :title="$t('produto.testado')"><a href="javascript:;"><i class="far fa-check-double"></i></a></li>
               <li v-if="produto.isSeguro" :title="$t('produto.semVirus')"><a href="javascript:;"><i class="far fa-shield"></i></a></li>
            </ul>
         </div>
      </div>
      <div class="product__content text-center">
         <h1 class="product-name mt-2 mb-1">
            <router-link :to="'/produto/'+ produto.tipoProduto +'/'+ produto.id" class="product-item-link">{{ produto.nome }}</router-link>
         </h1>
         <p class="description my-2 d-none d-sm-flex">
            <small class="limitador-3 font-13">{{ produto.descricao == null || String(produto.descricao).trim() == '' ? 'Sem descrição' : produto.descricao }}</small>
         </p>
         <span class="price d-block" v-if="parseFloat(produto.valor) > 0">R$ {{ produto.valor == null ? '-' : parseFloat(produto.valor).toLocaleString('pt-br', {minimumFractionDigits: 2, maximumFractionDigits:2}) }}</span>
         <span class="price d-block" v-else>{{ $t('header.carrinhoGratuito') }}</span>
      </div>
      <div class="product__add-btn" v-if="isLogado && produto.valor != null">
         <button type="button" @click="addCarrinho"><i class="far fa-shopping-cart me-1"></i> {{ $t('header.carrinho') }}</button>
      </div>
   </div>
</template>

<script>

import { mapState } from 'vuex'

export default {
   name: 'Produto',
   props: ['produto', 'view'],
   computed: {
		... mapState({
			isLogado: state => state.isLogado
		})
	},
   methods: {
      imageError : function (e) {
         this.$store.dispatch('imageError', e)
      },
      addCarrinho : function () {
         this.$store.dispatch('addCarrinho', JSON.parse(JSON.stringify(this.produto)))
      }
   }
}

</script>

<style scoped>

.ratio-3x4 {
   --bs-aspect-ratio: calc(4 / 3* 100%);
}

img {
	object-fit: cover;
   border-radius: 3px;
}

@media (min-width: 767px) {
   .carousel-250 {
      min-width: 250px !important;
   }
}

@media (max-width: 767px) {
   button {
      line-height: 2.4 !important;
      font-size: 14px !important;
   }

   .product-item-link {
      font-size: 13px !important;
   }

   .price {
      font-size: 14px !important;
   }
}

</style>